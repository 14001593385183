.main{
    margin-bottom: 50px;
}
#headline {
  color: #00ffd0;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  font-size: 3rem; /* Adjust font size for smaller screens */
}

#subheadline {
  margin-top: 10px;
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  #headline {
    font-size: 2.5rem; /* Adjust headline font size for smaller screens */
  }

  .welcome h1 {
    padding: 2px; /* Adjust padding for smaller screens */
    font-size: 2.5rem; /* Adjust font size for smaller screens */
  }

  .welcome h4 {
    padding: 10px; /* Adjust padding for smaller screens */
    font-size: 1.3rem; /* Adjust font size for smaller screens */
  }

  .welcome p {
    padding: 10px 0; /* Increase padding above and below the text */
    font-size: 1.7rem; /* Adjust font size for smaller screens */
    width: 95%; /* Make the container wider on small screens */
    text-align: center; /* Center the text */
    margin: 0 auto; /* Center the element itself */
  }

  .intro, .digok {
    width: 95%; /* Adjust width for smaller screens */
    margin: 0 auto; /* Center the element */
    text-align: center;
    padding: 1%;
    border-radius: 10px;
    margin-bottom: 10px; /* Ensure some space between sections */
  }

  .Me {
    width: 50%; /* Adjust the width for phone screens */
    max-width: 200px; /* Limit maximum width for responsiveness */
    height: auto; /* Maintain aspect ratio */
    margin: 10px auto; /* Center the image horizontally */
  }
}

.intro {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* background: #5d8af488; */
  padding: 1%;
  border-radius: 10px;
  margin-bottom: 0;
}

.digok {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #5d8af488;
  padding: 1%;
  border-radius: 10px;
}



.Me {
  height: auto;
  width: 12%;
}
/* Media query for phones */
@media (max-width: 600px) {
  .Me {
    width: 50%; /* Adjust the width as needed for phone screens */
  }
}

.Me:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: spin 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

@keyframes spin {
  100% {
    transform: translate(1px, -2px) rotate(359deg);
  }
}

.test{
    margin-top: 20px;
}