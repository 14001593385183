.headerbutton {
  padding: 1.3em 3em;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #fff;
  background-color: transparent; /* Make background color transparent */
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: none;
}

.headerbutton:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.headerbutton:active {
  transform: translateY(-1px);
}

.ib:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #23c483;
}

.ib:active {
  transform: translateY(-1px);
}