.App {
  background: linear-gradient(-45deg, #152d50, #143f69);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-size: calc(10px + 2vmin);
  color: rgb(203, 234, 255);
  font-family: "Times New Roman", Times, serif;
  min-height: 100vh; /* Ensure background covers entire viewport height */

}


/* .timetableSection {
  display: flex;
  justify-content: center;
} */
